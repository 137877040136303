import { toTitleCase } from '@/utils/string/toTitleCase'

// Filters for child categories under current parent category
export function getAlgoliaChildCategoriesFromFacets(facet: Record<string, number> | undefined) {
	if (!facet) return null

	return Object.entries(facet)
		.sort(([a], [b]) => (a ?? '').localeCompare(b ?? ''))
		.map(([parentChildCategory, childAppCount]) => {
			const [parentCategory, childCategory] = parentChildCategory.split(' > ')

			const childCategoryTitle = toTitleCase(childCategory)

			return {
				title: childCategoryTitle,
				slug: childCategory,
				appCount: childAppCount,
				parentCategory: {
					title: toTitleCase(parentCategory),
					slug: parentCategory
				}
			}
		})
}

export async function getAllAppAlgoliaFacets() {
	try {
		const allAppFacetsRes = await fetch('/api/algolia/marketplace/apps/all-facets/')
		const data = await allAppFacetsRes.json()
		return data
	} catch (e) {
		console.error('Error with getAllAppAlgoliaFacets', e)
		return null
	}
}
